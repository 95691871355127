import React, { useState, useEffect, useRef, useContext } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo-2.png";
import wpp from "../../assets/img/whatsapp.svg";
import { AuthContext } from "../../context/AuthContext";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";

const Navbar = () => {
  const { logout } = useContext(AuthContext);
  const [boxStyle, setBoxStyle] = useState({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) scale(1)",
    width: {
      xs: "80%", 
      sm: 360,
      md: 500,
      lg: 600,
    },
    maxHeight: "90vh",
    bgcolor: "#121212",
    color: "white",
    border: "none",
    boxShadow: "0px 0px 20px 5px rgba(0, 255, 0, 0.7)",
    borderRadius: "0px",
    p: { xs: 2, sm: 4 },
    opacity: 1,
    transition: "opacity 0.3s ease, transform 0.3s ease",
    overflowY: "auto",
  });

  const [open, setOpen] = useState(false);
  const [isNotificationVisible, setIsNotificationVisible] = useState(
    !localStorage.getItem("notificationDismissed")
  );

  useEffect(() => {
    const lastShown = localStorage.getItem("modalLastShown");
    const now = new Date().getTime();
    const twoDaysInMs = 2 * 24 * 60 * 60 * 1000;

    if (!lastShown || now - lastShown > twoDaysInMs) {
      setBoxStyle((prevStyle) => ({
        ...prevStyle,
        opacity: 1,
        transform: "translate(-50%, -50%) scale(1)",
      }));
      setOpen(true);
    }
  }, []);

  const handleOpen = () => {
    setBoxStyle((prevStyle) => ({
      ...prevStyle,
      opacity: 1,
      transform: "translate(-50%, -50%) scale(1)",
    }));
    setOpen(true);

    // Remove o span ao clicar na imagem
    setIsNotificationVisible(false);
    localStorage.setItem("notificationDismissed", "true");
  };

  const handleClose = () => {
    setBoxStyle((prevStyle) => ({
      ...prevStyle,
      opacity: 0,
      transform: "translate(-50%, -50%) scale(0.9)",
    }));
    setTimeout(() => {
      setOpen(false);
      localStorage.setItem("modalLastShown", new Date().getTime());
    }, 300);
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMenuClick = () => {
    setIsSidebarOpen(false);
  };

  const handleLogout = () => {
    logout();
    window.location.href = "/login";
  };

  return (
    <div>
      <nav
        className={`fixed top-0 left-0 w-full h-16 px-10 flex justify-between items-center z-50 transition-colors duration-300 ${
          scrolled || isSidebarOpen ? "navbar-color" : "bg-transparent"
        }`}
      >
        <button
          onClick={toggleSidebar}
          className="text-white hover:text-green-500 focus:outline-none flex items-center space-x-2"
        >
          {isSidebarOpen ? (
            <XIcon className="w-6 h-6" />
          ) : (
            <MenuIcon className="w-6 h-6" />
          )}
        </button>

        <div style={{ position: 'relative', display: 'inline-block' }}>
          <img
            onClick={handleOpen}
            src={logo}
            alt="Logo"
            className="h-10 md:h-10 lg:h-10 pl-10 ml-10"
          />
          {isNotificationVisible && (
            <span
              style={{
                position: 'absolute',
                bottom: '-12px',
                right: '-7px',
                backgroundColor: 'red',
                color: 'white',
                borderRadius: '50%',
                padding: '2px 6px',
                fontSize: '10px',
                fontWeight: 'bold',
                lineHeight: '1',
              }}
            >
              1
            </span>
          )}
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, position: "relative" }}>
            <Tooltip title="Clique aqui para fechar" arrow>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "red",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Atualizações da Semana
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Novo Gênero Axé " />
              </ListItem>
              <ListItem>
                <ListItemText primary="Nova Pasta: Grelo - Seresta " />
              </ListItem>
              <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 1 }}>
                Atualizações
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                Rock
              </Typography>
              <ListItem>
                <ListItemText primary="Agora a pasta de rock foi totalmente reformulada e repleta de conteúdo, mais de 600 novas músicas adicionadas!" />
              </ListItem>
            </List>
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <Button onClick={handleClose} variant="contained" color="success">
                OK
              </Button>
            </Box>
          </Box>
        </Modal>
        <div className="hidden md:flex items-center">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://wa.me/5519971400798?text=Estou%20precisando%20de%20suporte%2C%20pode%20me%20ajudar%3F"
            className="bg-green-500 text-black font-semibold hover:bg-green-600 rounded-lg flex items-center px-4 py-2"
          >
            <img src={wpp} alt="WhatsApp" className="w-6 h-6 mr-2" />
            SUPORTE
          </a>
        </div>
      </nav>

      <div
        ref={sidebarRef}
        className={`sidebar ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } fixed top-12 left-0 w-64 h-full p-4 transition-transform transform z-40`}
      >
        <nav className="flex text-center flex-col space-y-2">
          <Link
            to="/"
            className="text-white p-2 hover:bg-green-500 hover:text-black transition-colors"
            onClick={handleMenuClick}
          >
            Ouvir
          </Link>
          <Link
            to="/downloads"
            className="text-white p-2 hover:bg-green-500 hover:text-black transition-colors"
            onClick={handleMenuClick}
          >
            Baixar
          </Link>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://wa.me/5519997521609?text=Estou%20precisando%20de%20suporte%2C%20pode%20me%20ajudar%3F"
            className="text-white p-2 hover:bg-green-500 hover:text-black transition-colors md:hidden"
          >
            Suporte
          </a>
          <button
            onClick={handleLogout}
            className="text-white p-2 hover:bg-red-500 hover:text-black transition-colors"
          >
            Sair
          </button>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
